import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import './App.css';
import Loading from './components/Loading';

const PagamentoContainer = React.lazy( () => import('./containers/PagamentoContainer') );
const Redir = React.lazy( () => import('./containers/Redir') );
const Ops = React.lazy( () => import('./containers/Ops') );

const loading = () => <Loading show={true}/>;

function App() {
  return (
    <HashRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
        <Route exact path="/">
          <Redirect to="/redir" />
        </Route>
          {/*<Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />*/}
          {/*<Route path="/" name="Home" render={props => (isAuthenticated() ? <HomeContainer {...props}/> : <Login {...props}/> )} />*/}
          <Route path="/redir/:token" name="redirect" render={props => <Redir {...props}/>} />
          <Route path="/pay" name="Pagamento" render={props => <PagamentoContainer {...props}/> } />
          <Route path="/ops" name="Ops"  render={props => <Ops {...props}/> } />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}

export default App;
